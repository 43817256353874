import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useCallback, useEffect } from 'react';
import { gaEvent } from '../lib/gtag';
import { useApp } from '../providers/AppContext';
import { actionDimensionIds, MATOMO_ACTION, MATOMO_NAME } from './constants';
import useDeviceInfo from './useDeviceInfo';
import useNetworkType from './useNetworkInfo';
import { getUserCommonDimensions } from './utils';

const useTrackPageEvent = ({
  type,
  id,
  slug,
  title,
  category,
  statusSuccess
}) => {
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const {
    state: { currentUser, loading }
  } = useApp();

  const formContentDimension = useCallback(() => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );
    const contentObjectForVideo = [
      { id: actionDimensionIds.content_id, value: id },
      { id: actionDimensionIds.content_title, value: title },
      { id: actionDimensionIds.content_slug, value: slug },
      ...userDimensions
    ];
    return contentObjectForVideo;
  }, [device, id, networkType, title, currentUser, slug]);

  useEffect(() => {
    if (loading) return;

    const eventData = {
      action: MATOMO_ACTION.LOAD,
      name: MATOMO_NAME.PAGE_VIEW
    };

    trackEvent({
      category,
      action: eventData?.action,
      name: eventData?.name,
      customDimensions: formContentDimension(),
      value: statusSuccess
    });
    const gaEventData = {
      ...eventData
    };

    // Remove the email field from gaEventData
    delete gaEventData.email;
    gaEvent({
      category,
      action: gaEventData?.action,
      name: JSON.stringify(gaEventData),
      value: statusSuccess
    });
  }, [
    currentUser,
    loading,
    type,
    id,
    slug,
    title,
    category,
    statusSuccess,
    trackEvent,
    formContentDimension
  ]);

  return null;
};

export default useTrackPageEvent;
